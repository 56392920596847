import React, { Component } from 'react';
import lancamentoDataService from "../services/lancamento.service";
import saldoDataService from "../services/saldo.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import { toast } from 'react-toastify';
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, capitalizeFirstLetter, showMessageError, showMessageSuccess } from '../utils';
import TextInput from './Common/TextInput';

// const columns = [
//   { name: '#', selector: 'id', sortable: true, },
//   // { name: 'descricao', selector: 'descricao', sortable: true,  cell: row => <>{row.user === null ? 'Usuário inválido' : `${row.user.nome} (${row.user.email})` } </>, },
//   // { name: 'Plano', selector: 'planoId', sortable: true, wrap: true, compact: true,  cell: row => <>{row.plano === null ? 'Sem plano ou com Código ' : row.plano.titulo} </>,},
//   { name: 'referencia', selector: 'referencia', sortable: true, right: true, },
//   { name: 'descricao', selector: 'descricao', sortable: true, right: true, },
//   { name: 'datavencimento', selector: 'datavencimento', sortable: true, compact: true, },
//   { name: 'datapagamento', selector: 'datapagamento', sortable: true, compact: true, },
//   { name: 'valor', selector: 'valor', sortable: true, compact: true, },
//   { name: 'valorpago', selector: 'valorpago', sortable: true, compact: true, },
//   { name: 'Valor Pendente', sortable: true, right: true,   cell: row => <>{row.valorpago === null || row.valorpago === 0 ? row.valor : "0.00" } </>},
//   // { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
// ]
const handleButtonClick = (state) => {
  window.location = "/editar-motorista?id=" + state.id;
};

class SaldoEditar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined
      , referencia: undefined
      , descricao: undefined
      , observacao: undefined
      , datavencimento: undefined
      , valor: undefined
      , datapagamento: undefined
      , valorpago: undefined
      , contaOrigem_id: undefined
      , contaDestino_id: undefined
      , categoria_id: undefined
      , formapagamento_id: undefined
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
  }
  handleDataChange = (event, editor) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleLogout = () => {

  }

  handleSubmit = (event) => {
    event.preventDefault();

    let data = JSON.stringify(this.state);
    console.log(data);
    
    if (this.state.id !== '') {


      saldoDataService.update(this.state.id, data)
        .then(response => {
          this.props.history.push('/lancamentos')
          showMessageSuccess(response.data.message, );
        })
        .catch(e => {

          let message = e.response !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          showMessageError(message, {});

        });
    }
    else {
      saldoDataService.create(data)
        .then(response => {
          window.location = APP_URL() + './lancamentos';
          showMessageSuccess(response.data.message);
          // showMessage('', response.data.message, true, this.entendiHandle, 'Entendi', this.closeHandle)

        })
        .catch(e => {

          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';
          showMessageError(message, {});

        });
    }
  
  };
  getsaldo(saldo) {
    // let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);

    saldoDataService.get(saldo)
      .then(response => {
        this.setState({
          id: response.data.id,
          conta_id: response.data.conta_id,
          conta_descricao: response.data.conta[0].descricao,
          datasaldo: response.data.datasaldo.substring(0, 7),
          saldo: response.data.saldo,
          Mensagem: 'Nenhum registro para exibir'
        });
        console.log.apply(response.data)
      })
      .catch(e => {
        //console.log(e);
      });
  }



  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let saldo = query.has('id') ? query.get('id') : (this.props.match.params.id !== undefined ? this.props.match.params.id : '-');

    this.getsaldo(saldo);
  }
  render() {


    return (

      <div className="page-wrapper" >
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title={(this.state.id !== undefined ? 'Editando' : 'Inserindo') + " saldo " + this.state.id} ></Header>
        {/* <section className="course-one course-one__teacher-details home-one"> */}
        <Container className='pb-5'>
          <form onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={12} className="form-group">
                <TextInput type="hidden" labelValue={"id"} id={"id"} name={"id"} defaultValue={this.state.id} value={this.state.id} className="form-control" />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="form-group">
                <TextInput type={"month"} onChange={this.handleDataChange} readonly={true} labelValue={"referencia"} id={"referencia"} name={"referencia"} value={this.state.datasaldo} className="form-control" />
              </Col>
              <Col sm={12} className="form-group">
                <TextInput onChange={this.handleDataChange} readonly={true} labelValue={"Descrição"} id={"descricao"} name={"descricao"} value={this.state.conta_descricao} className="form-control" />
              </Col>

              <Col sm={12} className="form-group">
                <TextInput type={"number"} step="0.01" pattern="\d*" onChange={this.handleDataChange} labelValue={"saldo"} id={"saldo"} name={"saldo"} value={this.state.saldo} className="form-control" />
              </Col>

            </Row>
            {/* <Row>
              <Col sm={12} className="form-group">
                <TextInput onChange={this.handleDataChange} labelValue={"datavencimento"} id={"datavencimento"} name={"datavencimento"} value={this.state.datavencimento} className="form-control" />
              </Col>
              <Col sm={6} className="form-group">
                <TextInput onChange={this.handleDataChange} labelValue={"valor"} id={"valor"} name={"valor"} value={this.state.valor} className="form-control" />
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="form-group">
                <TextInput onChange={this.handleDataChange} labelValue={"datapagamento"} id={"datapagamento"} name={"datapagamento"} value={this.state.datapagamento} className="form-control" />
              </Col>
              <Col sm={6} className="form-group">
                <TextInput onChange={this.handleDataChange} labelValue={"valorpago"} id={"valorpago"} name={"valorpago"} value={this.state.valorpago} className="form-control" />
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="form-group">
                <TextInput onChange={this.handleDataChange} labelValue={"contaOrigem_id"} id={"contaOrigem_id"} name={"contaOrigem_id"} value={this.state.contaOrigem_id} className="form-control" />
              </Col>
              <Col sm={12} className="form-group">
                <TextInput onChange={this.handleDataChange} labelValue={"contaDestino_id"} id={"contaDestino_id"} name={"contaDestino_id"} value={this.state.contaDestino_id} className="form-control" />
              </Col>
              <Col sm={12} className="form-group">
                <TextInput onChange={this.handleDataChange} labelValue={"categoria_id"} id={"categoria_id"} name={"categoria_id"} value={this.state.categoria_id} className="form-control" />
              </Col>
              <Col sm={12} className="form-group">
                <TextInput onChange={this.handleDataChange} labelValue={"formapagamento_id"} id={"formapagamento_id"} name={"formapagamento_id"} value={this.state.formapagamento_id} className="form-control" />
              </Col>

            </Row> */}
            <Row className="row">
              <Col md={12} className="form-group">
                <div className="float-end mt-2">
                  <button className="btn btn-primary me-2" type="submit">Salvar saldo</button>
                  <Link to="/lancamentos" className="btn btn-warning" type="button">Voltar</Link>
                </div>
              </Col>
              <Col className="form-group ">
                <div className="">

                </div>
              </Col>
            </Row>
          </form>
        </Container>

        {/* </section> */}

        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default SaldoEditar;
