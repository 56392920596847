
import React, { Component } from 'react';
import reactLogo from '../../assets/images/logo.jpg';
import { Link, NavLink } from 'react-router-dom';
import { isLogin, logout } from '../../utils';
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import getTraducao from '../../utils/traducao';
// import { isLogin } from '../utils';

class NavMain extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {
        logout();
        this.setState({
            isLogin: false
        })
        window.location.reload();
    }

    stricky = () => {
        var $ = require("jquery");

        if ($('.stricky').length) {
            $('.stricky').addClass('original').clone(true).insertAfter('.stricky').addClass('stricked-menu').removeClass('original');
        }
    }
    componentDidMount() {
        this.stricky();
    }

    render() {
        return (
            <>
                <header className="site-header site-header__header-one ">

                    {/* <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky"> */}
                    {/* <div className="container clearfix"> */}

                    {/*<!-- Collect the nav links, forms, and other content for toggling -->*/}
                    <Navbar bg="light" expand="xl" className="main-navigation  " sticky="top" fixed="top" >
                        {/*<!-- Brand and toggle get grouped for better mobile display -->*/}
                        <Container fluid={true} className={''}>
                            <Navbar.Brand href={"/"}><img src={reactLogo} className="main-logo" width="40" alt="Meu financeiro " /></Navbar.Brand>
                            {isLogin() === true
                                ?
                                <>
                                    <Navbar.Toggle aria-controls="navbarScroll" /> {/* text-right collapse navbar-collapse flex-grow-1 text-right*/}
                                    <Navbar.Collapse id="navbarScroll" className='header-navigation '>
                                        <Nav className=" navigation-box me-auto">
                                            <div className="header__social d-none d-lg-block d-md-block d-sm-block">
                                                {/* <Link to='#' className="pl-0"><i className="fab fa-facebook-f"></i></Link> */}
                                                {/* <Link to='#'><i className="fab fa-whatsapp"></i></Link> */}
                                                {/* <Link to='#' className="pl-2"><i className="fab fa-instagram"></i></Link> */}
                                                {/* <Link to='#'><i className="fab fa-twitter"></i></Link> */}
                                            </div>{/*<!-- /.header__social -->*/}
                                            {/* <button className="menu-toggler" data-target=".main-navigation">
                                    <span className="kipso-icon-menu"></span>
                                </button> */}
                                            {/* </div><!-- /.logo-box --> */}
                                        </Nav>

                                        {/* <Nav className=" navigation-box"> */}

                                        <Nav className="navigation-box ms-auto my-2 my-lg-0 "
                                            style={{ maxHeight: '250px' }}
                                            navbarScroll activeKey="/">
                                            {/* <Nav.Item><NavLink to="/" exact activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Home")}</NavLink></Nav.Item>
                                        <Nav.Item><NavLink to="/sobre" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Sobre nós")}</NavLink></Nav.Item>
                                        <Nav.Item><NavLink to="/saibamais" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Saiba mais")}</NavLink></Nav.Item> */}
                                            
                                            {/* <Nav.Item><NavLink to="/contato" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Contato")}</NavLink></Nav.Item> */}

                                            {isLogin() ===true
                                                ?
                                                <>
                                                    {/* <Nav.Item><NavLink to="./nova-assinatura" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Assinatura")}</NavLink></Nav.Item> */}
                                                    <Nav.Item><NavLink exact tag={Link} className="nav-link" to="/"                     activeStyle={{ opacity: 1, fontWeight: "bold", color: "black" }}>{getTraducao("Home")}</NavLink></Nav.Item>
                                                    <Nav.Item><NavLink exact tag={Link} className="nav-link" to="/lancamentos"          activeStyle={{ opacity: 1, fontWeight: "bold", color: "black" }}>{getTraducao("Lancamentos")}</NavLink></Nav.Item>
                                                    <Nav.Item><NavLink exact tag={Link} className="nav-link" to="/lancamento/futuro"    activeStyle={{ opacity: 1, fontWeight: "bold", color: "black" }}>{getTraducao("Lanç. Futuro")}</NavLink></Nav.Item>
                                                    <Nav.Item><NavLink exact tag={Link} className="nav-link" to="/saldos"               activeStyle={{ opacity: 1, fontWeight: "bold", color: "black" }}>{getTraducao("Saldos")}</NavLink></Nav.Item>
                                                    <Nav.Item><NavLink exact tag={Link} className="nav-link" to="/contas"               activeStyle={{ opacity: 1, fontWeight: "bold", color: "black" }}>{getTraducao("Contas")}</NavLink></Nav.Item>
                                                    <Nav.Item><NavLink exact tag={Link} className="nav-link" to="/categorias"           activeStyle={{ opacity: 1, fontWeight: "bold", color: "black" }}>{getTraducao("Categorias")}</NavLink></Nav.Item>
                                                    <Nav.Item><Nav.Link tag={Link} className="" to="#" onClick={() => this.handleLogout()} /*activeStyle={{ opacity: 1, fontWeight: "bold", color: "black" }}*/ >{getTraducao("Sair")}</Nav.Link></Nav.Item>
                                                </>
                                                : <></>
                                            }

                                        </Nav>

                                    </Navbar.Collapse>
                                </>
                                :
                                <></>
                            }
                        </Container>
                    </Navbar> 

                    
                    <Container fluid={true} className={'p-0 m-0'}>
                        <Row className={'p-0 m-0'}>
                            <Col className={'p-0 m-0'}>
                                <div className="site-header__decor p-0 m-0 text-center">
                                    <div className="site-header__decor-row p-0 m-0">
                                        <div className="site-header__decor-single">
                                            <div className="site-header__decor-inner-11"></div>{/*<!-- /.site-header__decor-inner -->*/}
                                        </div>{/*<!-- /.site-header__decor-single -->*/}
                                        <div className="site-header__decor-single">
                                            <div className="site-header__decor-inner-12"></div>{/*<!-- /.site-header__decor-inner -->*/}
                                        </div>{/*<!-- /.site-header__decor-single -->*/}
                                        <div className="site-header__decor-single">
                                            <div className="site-header__decor-inner-13"></div>{/*<!-- /.site-header__decor-inner -->*/}
                                        </div>{/*<!-- /.site-header__decor-single -->*/}
                                    </div>{/*<!-- /.site-header__decor-row -->*/}
                                </div>{/*<!-- /.site-header__decor -->*/}
                            </Col>
                        </Row>
                    </Container>
                </header>
            </>
        );
    }
}
export default NavMain;