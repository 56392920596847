const { getLang } = require(".");

var traducao = require("./config");
const getTraducao = function () {

    var a = 0 < arguments.length && arguments[0] !== void 0 ? arguments[0] : "sandbox";//,

    var lang = getLang();

    try {
        try {        
            var retorno = traducao[a][lang];
            var retorno2 = (traducao[a]['pt-BR'] === undefined ? a : traducao[a]['pt-BR'])
            return retorno === undefined ? retorno2 : retorno;
        }
        catch {
            return a;
        }
    } catch (error) {
        return ""
    }
};
export default getTraducao

