
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet'

class Header extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {
        return (
            <Container fluid={true}>
                <Helmet><title>{(this.props.title !== undefined ? this.props.title + " | " : "") + 'Admin - Campos Brito | Meu financeiro'}</title></Helmet>
                {
                    (this.props.title !== 'Login')
                        ?

                        <Container fluid={this.props.fluid !== undefined ?this.props.fluid:false} className="pt-4 pb-0">
                            <Row>
                                <Col className="m-auto m-sm-0">
                                    <h4>{(this.props.title !== undefined ? this.props.title : '')}</h4>

                                </Col>
                            </Row>
                        </Container>
                        : <></>
                }
            </Container>
        );
    }
}
export default Header;