import React, { Component } from 'react';
import lancamentoDataService from "../services/lancamento.service";
import saldoDataService from "../services/saldo.service";
import { isAfter, isBefore, areEqual, isSameDay } from "date-fns"
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table, Accordion, Badge } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, capitalizeFirstLetter, getReferenciaMonth, getReferenciaYear, showMessageConfirm, showMessagedefault, showMessageError, showMessageInfo, showMessageSuccess, showMessageWarn } from '../utils';
import { toast, ToastContainer } from 'react-toastify';
import { Line, Bar, Chart } from 'react-chartjs-2';

const handleButtonClick = (state) => {
  window.location = "/editar-lancamento";
};
class lancamentosFuturo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lancamentos: [],
      lancamentosResumo: [],
      saldos: [],
      totalentrada: 0,
      totalareceber: 0,
      totalrecebido: 0,
      totalsaida: 0,
      totalapagar: 0,
      totalpago: 0,
      total: 0,
      totalpendente: 0,
      saldototal: 0,
      DiaPrincipal: new Date(),
      DiaFiltro: getReferenciaYear() === null ? new Date() : new Date(getReferenciaYear(), getReferenciaMonth(), '01'),
      optionsPie: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: this.props.descricao,
          },
        },
      },
      chartDataPie: {
        labels: [],
        datasets: [
          {
            label: '',
            data: []
          }
        ]
      },
      AgendaMes: []
      , filtro: 'mensal'
      , mark: []
      , AgendaFiltro: []
      , Mensagem: "Carregando lancamentos"
    };

  }

  handleLogout = () => {

  }

  nextDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = (this.state.filtro === 'mensal' ? addMonths(this.state.DiaFiltro, parseInt(pDay)) : this.state.filtro === 'semanal' ? addWeeks(addDays(this.state.DiaPrincipal, 3), parseInt(pDay)) : addDays(this.state.DiaFiltro, parseInt(pDay)))
    if (pDay !== null) {
      this.setState({
        DiaPrincipal: newDay
      })
    }

    this.onClickDay(newDay);
  }
  handleLancamentoClick = (index) => {
    window.location = "/editar-lancamento/" + index;
  }
  handleSaldoClick = (index) => {
    window.location = "/editar-saldo/" + index;
  }
  handleNovoLancamento = () => {
    window.location = APP_URL() + "lancamento/novo";
  }
  handlecategoriaRelatorioClick = (index) => {
    // console.log(index);
    // localStorage.setItem('IdEmpresa', index);
    window.location = "/categoria/" + index + "/relatorio";
  }
  handleImportarReferenciaClick = e => {

    let textoBotaoUm = "Importar"
    let textoBotaoDois = "Cancelar"
    let pDiaFiltroCurrent = this.state.DiaFiltro;
    let hoje = new Date(pDiaFiltroCurrent === undefined ? this.state.DiaFiltro : pDiaFiltroCurrent);

    const handleBotaoUm = () => {

      lancamentoDataService.importarReferenciaAnterior(hoje.getFullYear(), hoje.getMonth() + 1)
        .then(response => {
          let pDiaFiltroCurrent = this.state.DiaFiltro;
          this.getlancamentos(pDiaFiltroCurrent);          
          showMessageInfo(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          toast.error(message);
        });

    }
    const handleBotaoDois = () => { }
    showMessageConfirm("Você tem certeza que deseja proceder com a importação?",
      "Essa operação não pode ser revertida",
      "",
      {

        position: toast.POSITION.TOP_CENTER,
        containerId: '*',
        // theme: "dark",
        autoClose: false,
      },
      textoBotaoUm,
      handleBotaoUm,
      textoBotaoDois,
      handleBotaoDois
    );
  }
  handleLancamentoDeleteClick = e => {

    let textoBotaoUm = "Apagar"
    let textoBotaoDois = "Cancelar"
    const handleBotaoUm = () => {

      lancamentoDataService.delete(e)
        .then(response => {
          let pDiaFiltroCurrent = this.state.DiaFiltro;
          this.getlancamentos(pDiaFiltroCurrent);
          showMessageInfo(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          toast.error(message);
        });

    }
    const handleBotaoDois = () => { }
    showMessageConfirm("Você tem certeza que deseja excluir esse lançamento?",
      "Essa operação não pode ser revertida",
      "Será apagada para sempre",
      {

        position: toast.POSITION.TOP_CENTER,
        containerId: '*',
        // theme: "dark",
        autoClose: false,
      },
      textoBotaoUm,
      handleBotaoUm,
      textoBotaoDois,
      handleBotaoDois
    );
  }
  handleLancamentoReceberClick = e => {

    let textoBotaoUm = "Confirmar"
    let textoBotaoDois = "Cancelar"
    const handleBotaoUm = () => {

      lancamentoDataService.receberpagamento(e)
        .then(response => {
          let pDiaFiltroCurrent = this.state.DiaFiltro;
          this.getlancamentos(pDiaFiltroCurrent);
          showMessageInfo(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          toast.error(message);
        });

    }
    const handleBotaoDois = () => { }
    showMessageConfirm("Você tem certeza que deseja confirmar esse pagamento?",
      "Será usada a data de hoje e o valor total do lançamento",
      "",
      {

        position: toast.POSITION.TOP_CENTER,
        containerId: '*',
        // theme: "dark",
        autoClose: false,
      },
      textoBotaoUm,
      handleBotaoUm,
      textoBotaoDois,
      handleBotaoDois,
      'btn-success',
      'btn-light'
    );
  }
  clickDay = e => {
    e.preventDefault();
    let pDay = e.target.getAttribute('data-key');
    let newDay = new Date(pDay);

    if ((pDay !== null)) {
      this.filtraAgenda(newDay.getDate(), newDay.getMonth(), newDay.getFullYear());
      if (this.state.filtro !== 'semanal') {
        this.setState({
          DiaPrincipal: newDay
        })
      }
    }
  }

  onClickDay(event) {
    this.filtraAgenda('01', event.getMonth(), event.getFullYear());
    localStorage.setItem('Referencia-month', event.getMonth());
    localStorage.setItem('Referencia-year', event.getFullYear());
  }

  filtraAgenda(day, month, year) {
    let pDiaFiltro = new Date(year, month, day);
    let pDiaFiltroCurrent = this.state.DiaFiltro;

    this.setState({
      AgendaFiltro: this.state.AgendaMes.filter(
        filtro => new Date(filtro.DataAgendamento).getDate() === pDiaFiltro.getDate()
          && new Date(filtro.DataAgendamento).getMonth() === pDiaFiltro.getMonth()
          && new Date(filtro.DataAgendamento).getFullYear() === pDiaFiltro.getFullYear()),
      DiaFiltro: pDiaFiltro
    });

    if (this.state.filtro !== 'semanal') {
      this.setState({
        DiaPrincipal: pDiaFiltro
      })
    }
    if (pDiaFiltroCurrent.getMonth() !== pDiaFiltro.getMonth() || pDiaFiltroCurrent.getFullYear() !== pDiaFiltro.getFullYear()) {

      this.setState({
        AgendaMes: [],
        mark: []

      })
      this.getlancamentos(pDiaFiltro);
    }
  }

  setFiltro = filter => {
    this.setState({ filtro: filter === 0 ? 'mensal' : (filter === 1 ? 'semanal' : 'diario') })

  }
  componentDidMount() {
    this.getlancamentosResumo();
    this.getlancamentos();
    // this.getsaldos();
    // this.getcategoriapie();

  }
  

  calcularTotais() {

    var _totalentrada = 0;
    var _totalareceber = 0;
    var _totalrecebido = 0;
    var _totalsaida = 0;
    var _totalapagar = 0;
    var _totalpago = 0;
    var _totalpendente = 0;

    this.state.lancamentos.forEach(element => {
      _totalpendente += element.datapagamento === null ? parseFloat(element.valor) : 0
      _totalentrada += element.tipolancamento === 'Entrada' ? parseFloat(element.valor) : 0
      _totalareceber += element.tipolancamento === 'Entrada' && element.datapagamento === null ? parseFloat(element.valor) : 0
      _totalrecebido += element.tipolancamento === 'Entrada' && element.datapagamento !== null ? parseFloat(element.valorpago) : 0
      _totalsaida += element.tipolancamento === 'Saída' ? parseFloat(element.valor) : 0
      _totalapagar += element.tipolancamento === 'Saída' && element.datapagamento === null ? parseFloat(element.valor) : 0
      _totalpago += element.tipolancamento === 'Saída' && element.datapagamento !== null ? parseFloat(element.valorpago) : 0
    });
    this.setState({
      totalentrada: _totalentrada,
      totalareceber: _totalareceber,
      totalrecebido: _totalrecebido,
      totalsaida: _totalsaida,
      totalapagar: _totalapagar,
      totalpago: _totalpago,
    })
  }

  calcularSaldoTotal() {
    var _total = 0.00;

    this.state.saldos.forEach(element => {
      _total += parseFloat(element.saldo)

    });
    this.setState({ saldototal: _total })
  }

  getlancamentosResumo(pDiaFiltro) {
    let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);
    lancamentoDataService.getAllLancamentosFuturosResumo()
      .then(response => {

        this.setState({
          lancamentosResumo: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
        this.calcularTotais();
      })
      .catch(e => {

      });
  }
  getlancamentos(pDiaFiltro) {
    let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);
    lancamentoDataService.getAllLancamentosFuturos()
      .then(response => {
        this.setState({
          lancamentos: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
        this.calcularTotais();
      })
      .catch(e => {

      });
  }

  render() {


    return (

      <div className="page-wrapper" >

        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Contas à pagar/receber" fluid={false}></Header>
        <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
        <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        <section className="course-one course-one__teacher-details home-one">
          <Container fluid={false} className=" mb-1 calendario b-0">
            <Row className="card-body  m-0 p-0 card-border-width-0 card-border-radius-12">
              {/* <Col xs={2} className="pt-1 p-0 ">
                <button className="btn btn-left float-start p-0 m-0" to="#" onClick={this.nextDay} ><b><i className="material-icons p-2" data-key={-1}>chevron_left</i></b></button>
              </Col> */}
              <Col xs={12} className="text-left pt-2 pb-2 mt-1 pl-0 me-0">
                {/* <h5 className="agenda-data">
                  {
                    capitalizeFirstLetter
                      (
                        this.state.filtro === 'mensal'
                          ?

                          format(new Date(this.state.DiaFiltro), 'MMMM yyyy', { locale: ptBR })
                          :
                          this.state.filtro === 'semanal'
                            ?
                            (format(addDays(this.state.DiaPrincipal, -3), "dd") + " a " + format(addDays(this.state.DiaPrincipal, +3), "dd") + " de " + format(this.state.DiaPrincipal, "MMMM yyyy", { locale: ptBR }))
                            :
                            ((format(this.state.DiaFiltro, 'yyyyMMdd') === format(new Date(), 'yyyyMMdd')) ? 'Hoje' : format(this.state.DiaFiltro, "dd/MM/yyyy"))

                      )}</h5> */}
                <h6 className="agenda-data-revisoes">
                  {
                    this.state.filtro === 'mensal'
                      ? this.state.lancamentos.length
                      : (
                        this.state.filtro === 'semanal'
                          ? this.state.AgendaMes.filter(filtro => new Date(filtro.DataAgendamento).getUTCFullYear() === this.state.DiaPrincipal.getUTCFullYear()
                            && new Date(filtro.DataAgendamento).getMonth() === this.state.DiaPrincipal.getMonth()
                            && (
                              new Date(filtro.DataAgendamento).getDate() >= addDays(this.state.DiaPrincipal, -3).getDate()
                              && new Date(filtro.DataAgendamento).getDate() <= addDays(this.state.DiaPrincipal, +3).getDate()
                            )
                          ).length
                          : this.state.lancamentos.length
                      )
                  }  lançamentos</h6>
              </Col>
              {/* <Col xs={2} className="text-right p-0 pt-1 " >
                <button className="btn btn-right float-end p-0 m-0 " to="#" onClick={this.nextDay} ><b><i className="material-icons  p-2" data-key={+1}>chevron_right</i></b></button>
              </Col> */}
            </Row>
          </Container>
          <Container fluid={false}>

            <Row >
              <Col className=''>
                <Col className=''>
                  {

                    (this.state.lancamentosResumo.filter(f => f.tipolancamento === 'Entrada').length > 0)
                      ?
                      <>
                        <h6>Entradas</h6>
                        <Accordion defaultActiveKey="0">
                          {
                            this.state.lancamentosResumo.filter(f => f.tipolancamento === 'Entrada').map(resumo =>
                              <Accordion.Item className="m-0 p-0" eventKey={resumo.categoria_descricao} key={resumo.categoria_descricao}>
                                <Accordion.Header className="m-0 p-0">
                                  <Container fluid={true} className="m-0 p-0">
                                    <Row>
                                      <Col className="">
                                        {resumo.categoria_descricao} <Badge bg="secondary">{resumo.contador} </Badge>
                                      </Col>
                                      <Col >
                                        <Badge bg="info float-end" ><NumberFormat className="text-right d-block  " value={parseFloat(resumo.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></Badge>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Accordion.Header>
                                <Accordion.Body className='p-0 m-0'>
                                  <Table striped bordered hover size="sm" className='small' >
                                    <thead>
                                      <tr>
                                        <th colSpan={3}></th>

                                        <th>Referência</th>
                                        <th>Categoria</th>
                                        {/* <th>Descrição</th> */}
                                        <th>Vencimento</th>
                                        {/* <th className='d-none d-xs-none d-sm-none d-md-table-cell   d-lg-table-cell   d-xl-table-cell  '>Pagamento</th> */}
                                        <th>Valor</th>
                                        {/* <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pendente</th> */}
                                        {/* <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pago</th> */}
                                        {/* <th>Pago</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>

                                      {

                                        this.state.lancamentos.filter(f => f.tipolancamento === 'Entrada' && f.categoria_id === resumo.categoria_id).sort(function (b, a) { return new Date(b.referencia) - new Date(a.referencia); }).map(data =>

                                          <tr key={data.id}>
                                            <td className='p-1 m-1'><Link to="#" onClick={() => this.handleLancamentoClick(data.id)} className={'small float-start'}><small><i className="material-icons p-0 m-0 medium" data-key={-1}>edit</i></small></Link></td>
                                            <td className='p-1 m-1'><Link to="#" onClick={() => this.handleLancamentoDeleteClick(data.id)} className={'small float-start'}><small><i className="material-icons p-0 m-0 medium" data-key={-1}>delete</i></small></Link></td>
                                            <td className='p-1 m-1'>
                                              {

                                                data.datapagamento === null
                                                  ?
                                                  // <>Não Pago</>
                                                  <>
                                                    <Link to="#" onClick={() => this.handleLancamentoReceberClick(data.id)} className={('small float-start ' + (isBefore(new Date(data.datavencimento), new Date()) === true ? 'text-danger' : 'text-info'))}><small><i className="material-icons p-0  medium" data-key={-1}>  payments</i></small></Link>
                                                  </>
                                                  :
                                                  <>
                                                    {
                                                      isBefore(new Date(data.datapagamento), new Date()) === true
                                                        ?
                                                        <><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></>
                                                        :
                                                        <small className={'small float-start'}><i className="material-icons p-0  medium" data-key={-1}>browse_gallery</i></small>
                                                    }
                                                  </>
                                              }
                                            </td>
                                            {/* <td><span className={"text-right d-block text-info"} ><Link to="#" onClick={() => this.handlecategoriaRelatorioClick(data.categoria_id)}><small><i className="material-icons p-0  medium" data-key={-1}>expand_more</i></small></Link></span></td> */}
                                            <td>{format(new Date(data.referencia), 'MM/yyyy', { locale: ptBR })}</td>
                                            <td>{data.descricao}</td>
                                            {/* <td>{</td> */}
                                            <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                                            {/* <td className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td> */}
                                            <td><NumberFormat className="text-right d-block  " value={parseFloat(data.valor)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                            {/* <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                                            <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td> */}
                                            {/* <td><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></td> */}
                                          </tr>
                                        )
                                      }
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>

                            )
                          }
                          <h6 className='text-end'><NumberFormat className="text-right d-block  " value={parseFloat(this.state.lancamentosResumo.filter(f => f.tipolancamento === 'Entrada').reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.valorpago), 0))} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></h6>
                        </Accordion>


                      </>
                      :
                      <></>
                  }
                </Col>
                <Col className=''>
                  <h6>Saídas</h6>
                  <Accordion defaultActiveKey="0">
                    {
                      this.state.lancamentosResumo.filter(f => f.tipolancamento === 'Saída').map(resumo =>
                        <Accordion.Item eventKey={resumo.categoria_id} key={resumo.categoria_descricao}>
                          <Accordion.Header>
                            <Container fluid={true} className="m-0 p-0">
                              <Row>
                                <Col className="">
                                  {resumo.categoria_descricao} <Badge bg="secondary">{resumo.contador} </Badge>
                                </Col>
                                <Col >

                                  <Badge bg="info float-end" ><NumberFormat className="text-right d-block  " value={parseFloat(resumo.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></Badge>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className='p-0 m-0'>
                            <Table striped bordered hover size="sm" className='small' >
                              <thead>
                                <tr>
                                  <th colSpan={3}></th>
                                  <th>Referência</th>
                                  <th>Categoria</th>
                                  {/* <th>Descrição</th> */}
                                  <th>Vencimento</th>
                                  {/* <th className='d-none d-xs-none d-sm-none d-md-table-cell   d-lg-table-cell   d-xl-table-cell  '>Pagamento</th> */}
                                  <th>Valor</th>
                                  {/* <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pendente</th> */}
                                  {/* <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pago</th> */}
                                  {/* <th>Pago</th> */}
                                </tr>
                              </thead>
                              <tbody>

                                {

                                  this.state.lancamentos.filter(f => f.tipolancamento === 'Saída' && f.categoria_id === resumo.categoria_id).sort(function (b, a) { return new Date(b.referencia) - new Date(a.referencia); }).map(data =>

                                    <tr key={data.id}>
                                      <td className='p-1 m-1'><Link to="#" onClick={() => this.handleLancamentoClick(data.id)} className={'small float-start'}><small><i className="material-icons p-0 m-0 medium" data-key={-1}>edit</i></small></Link></td>
                                      <td className='p-1 m-1'><Link to="#" onClick={() => this.handleLancamentoDeleteClick(data.id)} className={'small float-start'}><small><i className="material-icons p-0 m-0 medium" data-key={-1}>delete</i></small></Link></td>
                                      <td className='p-1 m-1'>
                                        {

                                          data.datapagamento === null
                                            ?
                                            // <>Não Pago</>
                                            <>
                                              <Link to="#" onClick={() => this.handleLancamentoReceberClick(data.id)} className={('small float-start ' + (isBefore(new Date(data.datavencimento), new Date()) === true ? 'text-danger' : 'text-info'))}><small><i className="material-icons p-0  medium" data-key={-1}>  payments</i></small></Link>
                                            </>
                                            :
                                            <>
                                              {
                                                isBefore(new Date(data.datapagamento), new Date()) === true
                                                  ?
                                                  <><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></>
                                                  :
                                                  <small className={'small float-start'}><i className="material-icons p-0  medium" data-key={-1}>browse_gallery</i></small>
                                              }
                                            </>
                                        }
                                      </td>
                                      {/* <td><span className={"text-right d-block text-info"} ><Link to="#" onClick={() => this.handlecategoriaRelatorioClick(data.categoria_id)}><small><i className="material-icons p-0  medium" data-key={-1}>expand_more</i></small></Link></span></td> */}
                                      <td>{format(new Date(data.referencia), 'MM/yyyy', { locale: ptBR })}</td>
                                      <td>{data.descricao}</td>
                                      <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                                      {/* <td className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td> */}
                                      <td><NumberFormat className="text-right d-block  " value={parseFloat(data.valor)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                      {/* <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                                            <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td> */}
                                      {/* <td><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></td> */}
                                    </tr>
                                  )
                                }
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>

                      )
                    }
                    <h6 className='text-end'><NumberFormat className="text-right d-block  " value={parseFloat(this.state.lancamentosResumo.filter(f => f.tipolancamento === 'Saída').reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.valorpago), 0))} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></h6>
                  </Accordion>
                  {/* </div> */}
                </Col>
                <Col className=''>
                  {
                    (this.state.lancamentos.filter(f => f.tipolancamento === 'Transferência').length > 0)
                      ?
                      <>
                        <h6>Transferencias</h6>
                        <Table striped bordered hover size="sm" className='small' >
                          <thead>
                            <tr>
                              <th colSpan={4}></th>

                              <th>Referência</th>
                                  <th>Categoria</th>
                              {/* <th>Descrição</th> */}
                              <th>Vencimento</th>
                              <th className='d-none d-xs-none d-sm-none d-md-table-cell   d-lg-table-cell   d-xl-table-cell  '>Pagamento</th>
                              <th>Valor</th>
                              <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pendente</th>
                              <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pago</th>
                              {/* <th>Pago</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.lancamentos.filter(f => f.tipolancamento === 'Transferência').map(data =>

                                <tr key={data.id}>
                                  <td>
                                    <Link to="#" onClick={() => this.handleLancamentoClick(data.id)} className={'small float-start'}><small><i className="material-icons p-0  medium" data-key={-1}>edit</i></small></Link>
                                  </td>
                                  <td>
                                    <Link to="#" onClick={() => this.handleLancamentoDeleteClick(data.id)} className={'small float-start'}><small><i className="material-icons p-0  medium" data-key={-1}>delete</i></small></Link>
                                  </td>

                                  <td>
                                    {

                                      data.datapagamento === null
                                        ?
                                        // <>Não Pago</>
                                        <>
                                          <Link to="#" onClick={() => this.handleLancamentoReceberClick(data.id)} className={('small float-start ' + (isBefore(new Date(data.datavencimento), new Date()) === true ? 'text-danger' : 'text-info'))}><small><i className="material-icons p-0  medium" data-key={-1}>  payments</i></small></Link>
                                        </>
                                        :
                                        <>
                                          {
                                            isBefore(new Date(data.datapagamento), new Date()) === true
                                              ?
                                              <><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></>
                                              :
                                              <small className={'small float-start'}><i className="material-icons p-0  medium" data-key={-1}>browse_gallery</i></small>
                                          }
                                        </>

                                    }
                                  </td>
                                  <td><span className={"text-right d-block text-info"} ><Link to="#" onClick={() => this.handlecategoriaRelatorioClick(data.categoria_id)}><small><i className="material-icons p-0  medium" data-key={-1}>expand_more</i></small></Link></span></td>
                                  <td>{format(new Date(data.referencia), 'MM/yyyy', { locale: ptBR })}</td>
                                  <td>{data.descricao}</td>
                                  <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                                  <td className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td>
                                  <td><NumberFormat className="text-right d-block  " value={parseFloat(data.valor)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                  <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                                  <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td>
                                  {/* <td><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></td> */}

                                </tr>

                              )
                            }
                          </tbody>
                        </Table>
                        <h6 className='text-end'><NumberFormat className="text-right d-block  " value={parseFloat(this.state.lancamentos.filter(f => f.tipolancamento === 'Transferência').reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.valor), 0))} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></h6>
                      </>
                      :
                      <></>
                  }
                  {/* </div> */}
                </Col>
                <Col className=''>
                  {
                    (this.state.lancamentos.filter(f => f.tipolancamento === 'Outros').length > 0)
                      ?
                      <>
                        <h6>Outros</h6>
                        <Table striped bordered hover size="sm" className='small' >
                          <thead>
                            <tr>
                              <th colSpan={3}></th>

                              <th>Referência</th>
                                  <th>Categoria</th>
                              {/* <th>Descrição</th> */}
                              <th>Vencimento</th>
                              <th className='d-none d-xs-none d-sm-none d-md-table-cell   d-lg-table-cell   d-xl-table-cell  '>Pagamento</th>
                              <th>Valor</th>
                              <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pendente</th>
                              <th className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>Valor Pago</th>
                              {/* <th>Pago</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.lancamentos.filter(f => f.tipolancamento === 'Outros').map(data =>

                                <tr key={data.id}>
                                  <td>
                                    <Link to="#" onClick={() => this.handleLancamentoClick(data.id)} className={'small float-start'}><small><i className="material-icons p-0  medium" data-key={-1}>edit</i></small></Link>
                                  </td>
                                  <td>
                                    <Link to="#" onClick={() => this.handleLancamentoDeleteClick(data.id)} className={'small float-start'}><small><i className="material-icons p-0  medium" data-key={-1}>delete</i></small></Link>
                                  </td>
                                  <td>
                                    {

                                      data.datapagamento === null
                                        ?
                                        // <>Não Pago</>
                                        <>
                                          <Link to="#" onClick={() => this.handleLancamentoReceberClick(data.id)} className={('small float-start ' + (isBefore(new Date(data.datavencimento), new Date()) === true ? 'text-danger' : 'text-info'))}><small><i className="material-icons p-0  medium" data-key={-1}>  payments</i></small></Link>
                                        </>
                                        :
                                        <>
                                          {
                                            isBefore(new Date(data.datapagamento), new Date()) === true
                                              ?
                                              <><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></>
                                              :
                                              <small className={'small float-start'}><i className="material-icons p-0  medium" data-key={-1}>browse_gallery</i></small>
                                          }
                                        </>

                                    }
                                  </td>
                                  <td>{format(new Date(data.referencia), 'MM/yyyy', { locale: ptBR })}</td>
                                  <td>{data.descricao}</td>
                                  <td>{format(new Date(data.datavencimento), 'dd/MM/yyyy', { locale: ptBR })}</td>
                                  <td className='d-none d-xs-none  d-sm-none  d-md-table-cell    d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? format(new Date(data.datapagamento), 'dd/MM/yyyy', { locale: ptBR }) : ""}</td>
                                  <td><NumberFormat className="text-right d-block  " value={parseFloat(data.valor)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                  <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.datapagamento !== null ? "-" : <NumberFormat className="text-right d-block" value={parseFloat(data.valor - data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />}</td>
                                  <td className='text-right d-none d-xs-none  d-sm-none  d-md-table-cell d-lg-table-cell     d-xl-table-cell   '>{data.valorpago > 0 ? <NumberFormat className="text-right d-block" value={parseFloat(data.valorpago)} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /> : "-"}</td>
                                  {/* <td><small><span className={"text-center d-block bold small " + (data.datapagamento === null ? "text-danger" : "text-info")} >{data.datapagamento !== null ? <i className="material-icons p-0 " data-key={-1}>check</i> : <i className="material-icons p-0" data-key={-1}>close</i>}</span></small></td> */}

                                </tr>

                              )
                            }
                          </tbody>

                        </Table>
                        <h6 className='text-end'><NumberFormat className="text-right d-block  " value={parseFloat(this.state.lancamentos.filter(f => f.tipolancamento === 'Outros').reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.valor), 0))} prefix="R$" decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></h6>
                      </>
                      :
                      <></>
                  }
                  {/* </div> */}
                </Col>

              </Col>

            </Row>
          </Container>
        </section>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default lancamentosFuturo;
