import React, { Component, useState } from 'react';
import lancamentoDataService from "../services/lancamento.service";
import saldoDataService from "../services/saldo.service";
import contaDataService from "../services/conta.service";
import categoriaDataService from "../services/categoria.service";
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';

// import 'react-toastify/dist/ReactToastify.css';
// import { useNavigate  } from 'react-router'
import { Redirect } from 'react-router';
// import { browserHistory } from './react-router'

// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import { format, addDays, addMonths, addWeeks } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';
import { APP_URL, getReferenciaDate, showMessageError, showMessageSuccess } from '../utils';
import TextInput from './Common/TextInput';
import RadioButton from './Common/RadioButton';
import Botao from './Common/Botao';

// const columns = [
//   { name: '#', selector: 'id', sortable: true, },
//   // { name: 'descricao', selector: 'descricao', sortable: true,  cell: row => <>{row.user === null ? 'Usuário inválido' : `${row.user.nome} (${row.user.email})` } </>, },
//   // { name: 'Plano', selector: 'planoId', sortable: true, wrap: true, compact: true,  cell: row => <>{row.plano === null ? 'Sem plano ou com Código ' : row.plano.titulo} </>,},
//   { name: 'referencia', selector: 'referencia', sortable: true, right: true, },
//   { name: 'descricao', selector: 'descricao', sortable: true, right: true, },
//   { name: 'datavencimento', selector: 'datavencimento', sortable: true, compact: true, },
//   { name: 'datapagamento', selector: 'datapagamento', sortable: true, compact: true, },
//   { name: 'valor', selector: 'valor', sortable: true, compact: true, },
//   { name: 'valorpago', selector: 'valorpago', sortable: true, compact: true, },
//   { name: 'Valor Pendente', sortable: true, right: true,   cell: row => <>{row.valorpago === null || row.valorpago === 0 ? row.valor : "0.00" } </>},
//   // { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
// ]
const handleButtonClick = (state) => {
  window.location = "/editar-motorista?id=" + state.id;
};

// const [show, setShow] = useState(false);
class LancamentoEditar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: undefined
      , referencia: getReferenciaDate().toISOString().substring(0, 7)
      , descricao: undefined
      , observacao: undefined
      , datavencimento: undefined
      , installments: 1
      , frequency: 1
      , valor: undefined
      , show: false
      , datapagamento: undefined
      , valorpago: undefined
      , contaOrigem_id: ""
      , contaDestino_id: ""
      , categoria_id: ""
      , tipoLancamento: "Despesa"
      , formapagamento_id: ""
      , lstcategorias: [], lstcontas: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.OnChangePag = this.OnChangePag.bind(this);
  }

  OnChangePag = (event) => {
    this.setState({ tipoLancamento: event });
  }
  handleDataChange = (event, editor) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleLogout = () => {

  }
  onSelect(event) {
    const selectedIndex = event.target.options.selectedIndex;

    this.setState({ [event.target.name]: event.target.options[selectedIndex].getAttribute('data-key') });
  }
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.id !== undefined) {

      let data = JSON.stringify({
        "referencia": this.state.referencia
        , "descricao": this.state.descricao
        , "observacao": this.state.observacao
        , "datavencimento": this.state.datavencimento
        , "valor": this.state.valor
        , "datapagamento": this.state.datapagamento
        , "valorpago": this.state.valorpago
        , "contaOrigem_id": (this.state.contaOrigem_id === "" ? null : this.state.contaOrigem_id)
        , "contaDestino_id": (this.state.contaDestino_id === "" ? null : this.state.contaDestino_id)
        , "categoria_id": (this.state.categoria_id === "" ? null : this.state.categoria_id)
        , "formapagamento_id": (this.state.formapagamento_id === "" ? null : this.state.formapagamento_id)
      });

      lancamentoDataService.update(this.state.id, data)
        .then(response => {
          this.props.history.push('/lancamentos');
          showMessageSuccess(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          showMessageError(message);
        });
    }
    else {

      let data = JSON.stringify({
        "id": this.state.id
        , "referencia": this.state.referencia
        , "descricao": this.state.descricao
        , "observacao": this.state.observacao
        , "datavencimento": this.state.datavencimento
        , "valor": this.state.valor
        , "installments": this.state.installments
        , "frequency": this.state.frequency
        , "datapagamento": this.state.datapagamento
        , "valorpago": this.state.valorpago
        , "contaOrigem_id": (this.state.contaOrigem_id === "" ? null : this.state.contaOrigem_id)
        , "contaDestino_id": (this.state.contaDestino_id === "" ? null : this.state.contaDestino_id)
        , "categoria_id": (this.state.categoria_id === "" ? null : this.state.categoria_id)
        , "formapagamento_id": (this.state.formapagamento_id === "" ? null : this.state.formapagamento_id)
      });
      lancamentoDataService.create(data)
        .then(response => {
          this.props.history.push('/lancamentos');
          showMessageSuccess(response.data.message);
        })
        .catch(e => {
          let message = e.response.data.message !== undefined ? e.response.data.message : e.message !== undefined ? e.message : 'Erro ao processar mensagem!';

          showMessageError(message);
        });
    }
  };



  getCategorias() {

    categoriaDataService.getAll()
      .then(response => {
        this.setState({
          lstcategorias: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
        this.calcularSaldoTotal();
      })
      .catch(e => {
        //console.log(e);
      });
  }

  getContas() {

    contaDataService.getAll()
      .then(response => {
        this.setState({
          lstcontas: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
      })
      .catch(e => {
        //console.log(e);
      });
  }

  getlancamento(lancamento) {
    // let hoje = new Date(pDiaFiltro === undefined ? this.state.DiaFiltro : pDiaFiltro);

    lancamentoDataService.get(lancamento)
      .then(response => {
        this.setState({
          id: response.data.id,
          referenciaStr: response.data.referencia,
          referencia: response.data.referencia.substring(0, 7),
          descricao: response.data.descricao,
          observacao: response.data.observacao,
          datavencimento: response.data.datavencimento.substring(0, 10),
          valor: response.data.valor,
          datapagamento: response.data.datapagamento !== null ? response.data.datapagamento.substring(0, 10) : response.data.datapagamento,
          valorpago: response.data.valorpago,
          contaOrigem_id: response.data.contaOrigem_id,
          contaDestino_id: response.data.contaDestino_id,
          categoria_id: response.data.categoria_id,
          formapagamento_id: response.data.formapagamento_id,
          Mensagem: 'Nenhum registro para exibir'
        });
        console.log.apply(response.data)
      })
      .catch(e => {
        //console.log(e);
      });
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let lancamento = query.has('id') ? query.get('id') : (this.props.match.params.id !== undefined ? this.props.match.params.id : '-');

    this.getlancamento(lancamento);
    this.getCategorias();
    this.getContas();
  }

  render() {

    if (this.state.redirect) {
      return <Redirect push to="/lancamentos" />;
    }

    return (

      <>
        <div className="page-wrapper" >
          <NavTop></NavTop>
          <NavMain></NavMain>
          <Header title={(this.state.id !== undefined ? 'Editando' : 'Inserindo') + " lançamento "} ></Header>
          <Container className='pb-5'>
            <form onSubmit={this.handleSubmit}>
              <Row>
                <Col sm={12} className="form-group">
                  <TextInput type="hidden" labelValue={"id"} id={"id"} name={"id"} defaultValue={this.state.id} value={this.state.id} className="form-control" />
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="form-group">
                  {'Tipo de Lançamento: ' + this.state.tipoLancamento}
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <button type='button' className="btn btn-success float-lg-start px-5 me-2" onClick={() => this.OnChangePag("Receita")}> Receita</button>
                  <button type='button' className="btn btn-danger float-lg-start px-5 me-2" onClick={() => this.OnChangePag("Despesa")} >Despesa</button>
                  <button type='button' className="btn btn-warning float-lg-start px-5 me-2" onClick={() => this.OnChangePag("Transferencia")} >Transferência</button>
                </Col>
              </Row>
              <hr class={"p-1 mb-1 " + (this.state.tipoLancamento === "Despesa" ? "bg-danger" : this.state.tipoLancamento === "Receita" ? "bg-success" : "bg-warning") + " border " + (this.state.tipoLancamento === "Despesa" ? "border-danger" : this.state.tipoLancamento === "Receita" ? "border-success" : "border-warning")}></hr>
              <Row>
                <Col sm={6} className="form-group">
                  <TextInput required={"required"} disabled={this.state.id !== undefined ? "disabled" : ""} type={"month"} onChange={this.handleDataChange} labelValue={"Referencia > " + this.state.referencia + " | " + this.state.referenciaStr} id={"referencia"} name={"referencia"} value={this.state.referencia} className="form-control" />
                </Col>
                <Col sm={6} className="form-group"></Col>
                <Col sm={4} className="form-group">
                  <label htmlFor={'categoria_id'} className={""}>{'Categoria'}-{this.state.categoria_id}</label>
                  <select required={"required"} name='categoria_id' id="categoria_id" className="form-control" onChange={this.onSelect} value={this.state.categoria_id} >
                    <option data-key={""} key={""} value={""}>{""} </option>
                    {
                      this.state.lstcategorias.map(e =>
                        <option data-key={e.id} key={e.id} value={e.id}>{e.descricao} </option>
                      )
                    }
                  </select>
                </Col>
                <Col sm={4} className="form-group">
                  <label htmlFor={'contaOrigem_id'} className={""}>{'Conta Origem'}-{this.state.contaOrigem_id}</label>
                  <select required={"required"} name='contaOrigem_id' id="contaOrigem_id" className="form-control" onChange={this.onSelect} value={this.state.contaOrigem_id} disabled={this.state.tipoLancamento === "Receita" || this.state.tipoLancamento === "Transferencia" ? "disabled" : ""}>
                    <option data-key={""} key={""} value={""}>{""} </option>
                    {
                      this.state.lstcontas.map(e =>
                        <option data-key={e.id} key={e.id} value={e.id}>{e.descricao} </option>
                      )
                    }
                  </select>
                </Col>
                <Col sm={4} className="form-group">
                  <label htmlFor={'contaDestino_id'} className={""}>{'Conta Destino'}-{this.state.contaDestino_id}</label>
                  <select required={"required"} name='contaDestino_id' id="contaDestino_id" className="form-control" onChange={this.onSelect} value={this.state.contaDestino_id} disabled={this.state.tipoLancamento === "Despesa" || this.state.tipoLancamento === "Transferencia" ? "disabled" : ""} >
                    <option data-key={""} key={""} value={""}>{""} </option>
                    {
                      this.state.lstcontas.map(e =>
                        <option data-key={e.id} key={e.id} value={e.id}>{e.descricao} </option>
                      )
                    }
                  </select>
                </Col>
                <Col sm={12} className="form-group">
                  <TextInput required={"required"} onChange={this.handleDataChange} labelValue={"Descrição"} id={"descricao"} name={"descricao"} value={this.state.descricao} className="form-control" />
                </Col>
              </Row>
              <Row>
                <Col sm={4} className="form-group">
                  <TextInput required={"required"} type={"date"} onChange={this.handleDataChange} labelValue={"Data de vencimento"} id={"datavencimento"} name={"datavencimento"} value={this.state.datavencimento} className="form-control" />
                </Col>
                <Col sm={4} className="form-group">
                  <TextInput required={"required"} type={"number"} step="0.01" onChange={this.handleDataChange} labelValue={"Valor"} id={"valor"} name={"valor"} value={this.state.valor} className="form-control" />
                </Col>
              </Row>
              <Row>
                <Col sm={4} className="form-group">
                  <TextInput type={"date"} onChange={this.handleDataChange} labelValue={"Data de pagamento"} id={"datapagamento"} name={"datapagamento"} value={this.state.datapagamento} className="form-control" />
                </Col>
                <Col sm={4} className="form-group">
                  <TextInput type={"number"} step="0.01" onChange={this.handleDataChange} labelValue={"Valor pago"} id={"valorpago"} name={"valorpago"} value={this.state.valorpago} className="form-control" />
                </Col>
              </Row>
              <Row className={'d-none'}>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"contaOrigem_id"} id={"contaOrigem_id"} name={"contaOrigem_id"} value={this.state.contaOrigem_id} className="form-control" />
                </Col>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"contaDestino_id"} id={"contaDestino_id"} name={"contaDestino_id"} value={this.state.contaDestino_id} className="form-control" />
                </Col>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"categoria_id"} id={"categoria_id"} name={"categoria_id"} value={this.state.categoria_id} className="form-control" />
                </Col>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"formapagamento_id"} id={"formapagamento_id"} name={"formapagamento_id"} value={this.state.formapagamento_id} className="form-control" />
                </Col>
              </Row>
              {this.state.id === undefined
                ?
                <Row>
                  <Col sm={4} className="form-group">
                    <TextInput required={"required"} type={"number"} step="1" min="1" onChange={this.handleDataChange} labelValue={"Parcelas"} id={"installments"} name={"installments"} value={this.state.installments} className="form-control" />
                  </Col>
                  <Col sm={4} className="form-group">
                    <label htmlFor={'frequency'} className={""}>{'Periodicidade'}-{this.state.frequency}</label>
                    <select required={"required"} name='frequency' id="frequency" className="form-control" onChange={this.onSelect} value={this.state.frequency} >
                      <option data-key="1" value="1" selected="selected">Mensal</option>
                      <option data-key="2" value="2" >Bimestral</option>
                      <option data-key="3" value="3" >Trimestral</option>
                      <option data-key="6" value="6" >Semestral</option>
                      <option data-key="12" value="12">Anual</option>
                    </select>
                  </Col>
                </Row>
                :
                <></>
              }

              <Row>
                <Col sm={12} className="form-group">
                  <TextInput onChange={this.handleDataChange} labelValue={"Observação"} id={"observacao"} name={"observacao"} value={this.state.observacao} className="form-control" />
                </Col>
              </Row>
              <hr></hr>
              <Row className="row">
                <Col xs={12} sm={12} md={12} className="form-group  text-right">
                  <div className="float-end mt-2">
                    <button className="btn btn-primary me-2" type="submit">Salvar lançamento</button>
                    <Link to="/lancamentos" className="btn btn-warning" type="button">Voltar</Link>
                  </div>
                </Col>
                <Col className="form-group col-md-4">
                  <div className=""></div>
                </Col>
              </Row>
            </form>
          </Container>
          <FooterMenu></FooterMenu>
          <Footer></Footer>
        </div >
      </>
    );
  }
}
export default LancamentoEditar;
