import http from "../utils/apiAvancada";

class ContaDataService {
  getAll() {
    return http.get("/conta/");
  }

  get(id) {
    return http.get(`/conta/${id}`);
  }

  create(data) {
    return http.post("/conta", data);
  }

  update(id, data) {
    return http.put(`/conta/${id}`, data);
  }

  delete(id) {
    return http.delete(`/conta/${id}`);
  }

  deleteAll() {
    return http.delete(`/conta`);
  }

  findByTitle(title) {
    return http.get(`/conta?title=${title}`);
  }
}

export default new ContaDataService();