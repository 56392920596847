
import React from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FeedbackConfirmarPagamento from '../components/FeedbackConfirmarPagamento'; 

const TOKEN_KEY = 'Token';
export const logout = () => {
    localStorage.removeItem('Id');
    localStorage.removeItem('Name');
    localStorage.removeItem('Email');
    localStorage.removeItem('Role');
    localStorage.removeItem('Token');
    localStorage.removeItem('Referencia-month');
    localStorage.removeItem('Referencia-year');
    console.log('logout');
    window.location.reload(true);
}
export const getLang = () => { return localStorage.getItem('Idioma') === null ? 'pt-BR' : localStorage.getItem('Idioma') }
export const getReferenciaYear = () => {

    return localStorage.getItem('Referencia-year');
}
export const getReferenciaMonth = () => {

    return localStorage.getItem('Referencia-month');
}
export const getReferenciaDate = () => {
    return getReferenciaYear() === null ? new Date() : new Date(getReferenciaYear(), getReferenciaMonth(), '01');
}
export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}
export const isProfileChosen = () => {
    return (parseInt(localStorage.getItem('IdEmpresa')) > 0);
}
export const getToken = () => {

    return localStorage.getItem(TOKEN_KEY);
}
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const API_URL = () => {
    return (process.env.NODE_ENV === 'development') ? 'http://localhost:8081/api' : 'https://api-meufinanceiro.camposbrito.com.br/api';
}
export const APP_URL = () => {
    return (process.env.NODE_ENV === 'development') ? 'http://localhost:9000/' : 'https://meu-financeiro.camposbrito.com.br/';
}
export const getId = () => {
    return localStorage.getItem('Id');
}
export const getEnv = () => {
    return process.env.NODE_ENV;
}
export const getIdEmpresa = () => {
    return localStorage.getItem('IdEmpresa');
}
export const getIdParceiro = () => {
    return localStorage.getItem('IdParceiro');
}
export const formSerialize = (formElement, stringify = false) => {

    const values = {};
    const inputs = formElement.elements; 
    for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].name !== "") {
            values[inputs[i].name] = inputs[i].value;
        }
    }
    if (stringify)
        return JSON.stringify(values)
    else
        return values;
}
export const serialize = (form, stringify = true) => {
    let requestArray = [];
    form.querySelectorAll('[name]').forEach((elem) => { 
        var jsonArg1 = [];
        jsonArg1.name = elem.name;
        jsonArg1.value = elem.value;
        if (elem.name !== '') {
            requestArray.push(jsonArg1);
        } 
    });
    if (requestArray.length > 0)

        if (stringify)
            return JSON.stringify(requestArray)
        else
            return requestArray;

    else
        return false;
}
export const showMessageSuccess = (mensagem, options = { containerId: '*' }) => {
    toast.success
        (
            mensagem,
            options
        );
}
export const showMessageError = (mensagem, options = { containerId: '*' }) => {
    toast.error(
        mensagem,
        options
    );
}
export const showMessageWarn = (mensagem, options = { containerId: '*' }) => {
    toast.warn(
        mensagem,
        options
    );
}
export const showMessageInfo = (mensagem, options = { containerId: '*' }) => {

    toast.info(
        mensagem,
        options
    );
}
export const showMessagedefault = (mensagem, options = { containerId: '*' }) => {
    toast(mensagem,
        options
    );
}
export const showMessageConfirm = (titulo, mensagem, detalhe, valor, data, descricao, formulario = false, options = { containerId: '*' }, textoBotaoUm, handleBotaoUm, textoBotaoDois, handleBotaoDois, ClassNameBotaoUm = 'btn-danger', ClassNameBotaoDois = 'btn-light') => {
    toast(
        <FeedbackConfirmarPagamento
            className={"m-0 p-0"}
            titulo={titulo}
            mensagem={mensagem + '\n' + detalhe}
            textoBotaoUm={textoBotaoUm}
            handleBotaoUm={handleBotaoUm}
            textoBotaoDois={textoBotaoDois}
            valorpago={valor}
            datavencimento={data}
            descricao={descricao}
            formulario={formulario}
            handleBotaoDois={handleBotaoDois}
            ClassNameBotaoUm={ClassNameBotaoUm}
            ClassNameBotaoDois={ClassNameBotaoDois} />,
        options
    );
}


export const showMessageConfirmPagamento = (titulo, mensagem, detalhe, valor, data, descricao, formulario = false, options = { containerId: '*' }, textoBotaoUm, handleBotaoUm, textoBotaoDois, handleBotaoDois, ClassNameBotaoUm = 'btn-danger', ClassNameBotaoDois = 'btn-light') => {   
    // const toastId =  
    toast(
        <FeedbackConfirmarPagamento
            className={"m-0 p-0"}
            titulo={titulo}
            mensagem={mensagem + '\n' + detalhe}
            textoBotaoUm={textoBotaoUm}
            handleBotaoUm={handleBotaoUm}
            textoBotaoDois={textoBotaoDois}
            valorpago={valor}
            datavencimento={data}
            descricao={descricao}
            formulario={formulario}
            handleBotaoDois={handleBotaoDois}
            ClassNameBotaoUm={ClassNameBotaoUm}
            ClassNameBotaoDois={ClassNameBotaoDois} />,
        options
    );
} 

export const getEmail = () => { return localStorage.getItem('Email'); }

