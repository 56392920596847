import http from "../utils/apiAvancada";

class categoriaDataService {
  getAll() {
    return http.get("/categoria/");
  }

  get(id) {
    return http.get(`/categoria/${id}`);
  }
  getHistorico(id) {
    return http.get(`/categoria/historico/${id}`);
  }

  create(data) {
    return http.post("/categoria", data);
  }

  update(id, data) {
    return http.put(`/categoria/${id}`, data);
  }

  delete(id) {
    return http.delete(`/categoria/${id}`);
  }

  deleteAll() {
    return http.delete(`/categoria`);
  }

  findByTitle(title) {
    return http.get(`/categoria?title=${title}`);
  }
}

export default new categoriaDataService();