import http from "../utils/apiAvancada";

class LoginDataService {
    signin(data) {
        return http.post("/auth/signin", data);
    }
    //   getAll() {
    //     return http.get("/instrumentos");
    //   }

    //   get(id) {
    //     return http.get(`/instrumentos/${id}`);
    //   }

    //   create(data) {

    //     return http.post("/instrumentos", data);
    //   }

    //   update(id, data) {
    //     return http.put(`/instrumentos/${id}`, data);
    //   }

    //   delete(id) {
    //     return http.delete(`/instrumentos/${id}`);
    //   }

    //   deleteAll() {
    //     return http.delete(`/instrumentos`);
    //   }

    //   findByTitle(title, lang) {
    //         return http.get(`/instrumentos?nome=${title}&lang=${lang}`);
    //   }
    //   findByTitleAndLang(title, lang) {
    //     return http.get(`/instrumentos/lang?nome=${title}&lang=${lang}`);
    //   }
}

export default new LoginDataService();