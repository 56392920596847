
import React, { Component/*, NavLink*/ } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Botao from './Common/Botao';
import TextInput from './Common/TextInput';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
class FeedbackConfirmarPagamento extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fecharDiv: false
        }
        this.handleDataChange = this.handleDataChange.bind(this);
    }
    handleDataChange = (event, editor) => {
        this.setState({ [event.target.name]: event.target.value });
        localStorage.setItem(event.target.name, event.target.value);
    }
    componentDidMount() {
        localStorage.setItem('valorpago', this.props.valorpago);
        localStorage.setItem('datapagamento', format(new Date(), 'yyyy-MM-dd', { locale: ptBR }));
        this.setState(
            {
                valorpago: this.props.valorpago,
                datapagamento: format(new Date(), 'yyyy-MM-dd', { locale: ptBR })
            }
        )
    }
    render() {
        return (

            <Container fluid={true} className={this.props.className}>

                <Row>
                    <Col>
                        <p className="feedback-titulo"><b className='m-0 p-0'>{this.props.titulo}</b></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="form-group text-center pb-4"> <label ><b>{this.props.descricao}</b></label></Col>
                </Row>
                {
                    this.props.formulario === true ?
                        <>
                            <Row>
                                <Col sm={12} className="form-group text-left">
                                    <TextInput type={"date"} labelValue={"Data de Vencimento"} id={"datavencimento"} name={"datavencimento"} readonly="true" disabled="true" value={this.props.datavencimento} className="form-control" />
                                    {/* <label >Vencimento: {format(new Date(this.props.datavencimento), 'dd/MM/yyyy')}</label> */}
                                </Col>
                                <Col sm={12} className="form-group text-left ">
                                    <TextInput type={"number"} step="0.01" value={this.props.valorpago} labelValue={"Valor"} id={"valor"} name={"valor"} readonly="true" disabled="true" className="form-control" />
                                    {/* <label >Valor: {this.props.valorpago}</label><hr className='mt-0 p-0'></hr> */}
                                </Col>
                                <Col sm={12} className="form-group">
                                    <TextInput type={"date"} labelValue={"Data de pagamento"} id={"datapagamento"} name={"datapagamento"} onChange={this.handleDataChange} value={this.state.datapagamento} className="form-control" />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className="form-group">
                                    <TextInput type={"number"} step="0.01" onChange={this.handleDataChange} value={this.state.valorpago} labelValue={"Valor pago"} id={"valorpago"} name={"valorpago"} className="form-control" />
                                </Col>
                            </Row>
                        </> :
                        <></>
                }
                {this.props.linkEntendi || this.props.textoLink !== "" ?
                    <Row>
                        <Col sm="12">
                            <Botao className="btn botao-feedback"
                                value={this.props.textoLink !== "" ? this.props.textoLink : "Entendi"}
                                BotaoLink="true"
                                FunctionClickCall={this.props.handleLink}></Botao>
                        </Col>
                    </Row>
                    :
                    ""
                }
                {this.props.handleBotao !== undefined && this.props.textoBotao !== "" ?
                    <Row>
                        <Col sm="12">
                            <Botao className="btn btn-primary me-2 col-12 mt-4"
                                value={this.props.textoBotao}
                                BotaoLink="true"
                                FunctionClickCall={this.props.handleBotao}></Botao>
                        </Col>
                    </Row>
                    :
                    ""
                }
                {this.props.handleBotaoUm !== undefined && this.props.textoBotaoUm !== "" &&
                    this.props.handleBotaoDois !== undefined && this.props.textoBotaoDois !== "" ?
                    <Row className='text-center'>
                        <Col xs={6}>
                            <Botao className={"btn float-end " + this.props.ClassNameBotaoUm}
                                value={this.props.textoBotaoUm}
                                BotaoLink="true"
                                with_row="true"
                                FunctionClickCall={this.props.handleBotaoUm}></Botao>
                        </Col>
                        <Col xs={6}>

                            <Botao className={"btn float-start " + this.props.ClassNameBotaoDois}
                                value={this.props.textoBotaoDois}
                                BotaoLink="true"
                                with_row="true"
                                FunctionClickCall={this.props.handleBotaoDois}></Botao>
                        </Col>
                    </Row>
                    :
                    ""
                }

            </Container>

        );
    }
}

export default FeedbackConfirmarPagamento;