import React, { Component } from 'react';

import packageJson from '../../../package.json';
// import { Col, Row } from 'react-bootstrap';
// import { Link, NavLink } from 'react-router-dom';
import { Link, } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getEnv } from '../../utils';

class FooterMenu extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {

    }

    render() {
        return (

            <footer className="site-footer">

                <div className="site-footer__bottom">
                    <div className="container">
                        <Row>
                            <div className="col-12 py-3 text-left site-footer__copy font-size-14 lh-14">
                            &copy; Copyright 2021 by - <Link to="#">Campos Brito | Meu Financeiro</Link>&nbsp;<small >Version - {packageJson.version} | Meu financeiro | {getEnv()}</small>
                            </div>
                        </Row>
                    </div>
                </div>
            </footer>


        );
    }
}
export default FooterMenu;