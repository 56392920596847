import http from "../utils/apiAvancada";

class SaldoDataService {
  getAll() {
    return http.get("/saldo/");
  }
  getSaldoProjecao(year, month) {
    return http.get(`/saldo/projecao/`);
  }
  getSaldoProjecaoReferencia(year, month) {
    return http.get(`/saldo/projecao/${year}/${month}`);
  }
  getAllref(year, month) {
    return http.get(`/saldo/${year}/${month}`);
  }
  get(id) {
    return http.get(`/saldo/${id}`);
  }

  create(data) {
    return http.post("/saldo", data);
  }

  update(id, data) {
    return http.put(`/saldo/${id}`, data);
  }

  delete(id) {
    return http.delete(`/saldo/${id}`);
  }

  deleteAll() {
    return http.delete(`/saldo`);
  }

  findByTitle(title) {
    return http.get(`/saldo?title=${title}`);
  }
}

export default new SaldoDataService();